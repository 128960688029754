import React, { useEffect } from "react";
import styles from "./MolalForm.module.scss";
import { GoArrowDown } from "react-icons/go";
import { useTranslation } from "react-i18next";
import useDeviceDetection from "../../../hooks/useDeviceDetection";

const Modal = ({ isVisible, onClose }) => {
  const device = useDeviceDetection;
  const { t } = useTranslation();
  useEffect(() => {
    if (isVisible) {
      console.log("Applying overflow: hidden to body");
      document.body.style.overflow = "hidden"; // Блокировка скролла
    } else {
      console.log("Removing overflow: hidden from body");
      document.body.style.overflow = ""; // Разблокировка скролла
    }

    return () => {
      console.log("Cleaning up on unmount");
      document.body.style.overflow = "";
    };
  }, [isVisible]);

  if (!isVisible) return null;

  const isHebrew = localStorage.getItem("language") === "heb";

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.contentContainer}>
          <button
            className={styles.closeButton}
            style={{
              left:
                device === "Mobile" ? undefined : isHebrew ? "-45vw" : "40vw",
            }}
            onClick={onClose}
          >
            &times;
          </button>
          <div className={styles.mobileContainer}>
            <div className={styles.subtitle}>{t("Contacts_us")}</div>
            <form className={styles.form}>
              <div className={styles.flexColumn}>
                <input
                  className={styles.input}
                  type="text"
                  name="firstName"
                  placeholder={t("Name")}
                />
                <input
                  className={styles.input}
                  type="text"
                  name="company"
                  placeholder={t("Company")}
                />
                <input
                  className={styles.input}
                  type="text"
                  name="jobTitle"
                  placeholder={t("Job_title")}
                />
                <input
                  className={styles.input}
                  type="email"
                  name="email"
                  placeholder={t("Email")}
                />
              </div>
              <div className={styles.checkBoxContainer}>
                <input type="checkbox" />
                <p className={styles.checkBoxText}>
                  {t("I_HAVE_READ_AND_AGREE")}
                </p>
              </div>
              <div className={styles.button} onClick={onClose}>
                <p className={styles.buttonText}>{t("submit")}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
