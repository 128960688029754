import Footer from "../../components/footer/Footer";
import Form from "../../components/Form/Form";
import TrueFooter from "../../components/TrueFooter/TrueFooter";
import styles from "./About.module.scss";
import { useRef, useEffect, useState } from "react";
import LocomotiveScroll from "locomotive-scroll";
import { useScroll, motion, useTransform } from "framer-motion";
import TitleSection from "../../components/aboutPage/TitleSection/TitleSection";
import ValueSection from "../../components/aboutPage/ValuesSection/ValuesSection";
import BackgroundAnimation from "../../components/aboutPage/BackgroundAnimation/BackgroundAnimation";
import TeamCarousel from "../../components/aboutPage/TeamCarousel/TeamCarousel";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import { useTranslation } from "react-i18next";

const startPosition = 0;
const firstSlideTopStart = startPosition;
const firstSlideTopEnd = firstSlideTopStart + 0.2;

const secondSlideTopStart = firstSlideTopStart + 0.1;
const secondSlideTopEnd = secondSlideTopStart + 0.2;
const thirdSlideTopStart = secondSlideTopEnd;
const thirdSlideTopEnd = thirdSlideTopStart + 0.1;
const fourthSlideTopStart = thirdSlideTopEnd;
const fourthSlideTopEnd = fourthSlideTopStart + 0.1;
const fifthSlideTopStart = fourthSlideTopEnd;
const fifthSlideTopEnd = fifthSlideTopStart + 0.1;
const sixSlideTopStart = fifthSlideTopEnd;
const sixSlideTopEnd = sixSlideTopStart + 0.1;
const formSlideTopStart = sixSlideTopEnd;
const formSlideTopEnd = formSlideTopStart + 0.1;
const footerSlideTopStart = formSlideTopEnd;
const footerSlideTopEnd = footerSlideTopStart + 0.1;

const firstWorthAppearStart = secondSlideTopStart + 0.05;
const firstWorthAppearEnd = firstWorthAppearStart + 0.04;
const secondWorthAppearStart = firstWorthAppearEnd;
const secondWorthAppearEnd = secondWorthAppearStart + 0.03;
const thirdWorthAppearStart = secondWorthAppearEnd;
const thirdWorthAppearEnd = thirdWorthAppearStart + 0.03;
const fourthWorthAppearStart = thirdWorthAppearEnd;
const fourthWorthAppearEnd = fourthWorthAppearStart + 0.03;
const fifthWorthAppearStart = fourthWorthAppearEnd;
const fifthWorthAppearEnd = fifthWorthAppearStart + 0.03;

const MobilefirstWorthAppearStart = secondSlideTopStart;
const MobilefirstWorthAppearEnd = MobilefirstWorthAppearStart + 0.03;
const MobilesecondWorthAppearStart = MobilefirstWorthAppearEnd;
const MobilesecondWorthAppearEnd = MobilesecondWorthAppearStart + 0.03;
const MobilethirdWorthAppearStart = MobilesecondWorthAppearEnd;
const MobilethirdWorthAppearEnd = MobilethirdWorthAppearStart + 0.04;
const MobilefourthWorthAppearStart = MobilethirdWorthAppearEnd;
const MobilefourthWorthAppearEnd = MobilefourthWorthAppearStart + 0.03;
const MobilefifthWorthAppearStart = MobilefourthWorthAppearEnd;
const MobilefifthWorthAppearEnd = MobilefifthWorthAppearStart + 0.03;

const About = () => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const device = useDeviceDetection();
  const { scrollYProgress } = useScroll();
  const [scrollInstance, setScrollInstance] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Контроль состояния меню
  const [isRtl, setIsRtl] = useState(false); // Состояние для проверки языка
  useEffect(() => {
    const language = localStorage.getItem("language"); // или ваш ключ
    setIsRtl(language === "heb"); // true, если иврит
  }, []);
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smoothMobile: true,
      inertia: 0.8,
    });
    setScrollInstance(scroll);
    window.scrollTo(0, 0);
    scrollYProgress.onChange((latest) => {
      const scrollPercentage = latest * 100; // Преобразуем в проценты
      console.log(`Page scrolled: ${scrollPercentage}%`);
    });
    return () => {
      if (scroll) scroll.destroy();
    };
  }, [scrollYProgress]);
  useEffect(() => {
    if (scrollInstance) {
      if (isMenuOpen) {
        scrollInstance.stop(); // Останавливаем скролл при открытом меню
      } else {
        scrollInstance.start(); // Включаем скролл при закрытом меню
      }
    }
  }, [isMenuOpen, scrollInstance]);

  const transforms = {
    first: useTransform(
      scrollYProgress,
      [firstSlideTopStart, firstSlideTopEnd],
      device === "Mobile" ? ["0vw", "-540vw"] : ["0vh", "-250vh"]
    ),
    second: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [
            secondSlideTopStart - 0.03,
            secondSlideTopEnd,
            thirdSlideTopStart,
            thirdSlideTopEnd,
          ]
        : [
            secondSlideTopStart,
            secondSlideTopEnd,
            thirdSlideTopStart,
            thirdSlideTopEnd,
          ],
      device === "Mobile"
        ? ["270vw", "-400vw", "-400vw", "-620vw"]
        : ["100vh", "-150vh", "-150vh", "-250vh"]
    ),
    third: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [
            thirdSlideTopStart - 0.04,
            thirdSlideTopEnd - 0.05,
            fourthSlideTopStart - 0.05,
            fifthSlideTopEnd - 0.05,
          ]
        : [
            thirdSlideTopStart,
            thirdSlideTopEnd,
            fourthSlideTopStart,
            fifthSlideTopEnd,
          ],
      device === "Mobile"
        ? ["270vw", "0vw", "0vw", "-230vw"]
        : ["100vh", "0vh", "0vh", "-100vh"]
    ),
    fourth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [
            fourthSlideTopStart - 0.05,
            fourthSlideTopEnd,
            fifthSlideTopStart,
            fifthSlideTopEnd,
          ]
        : [
            fourthSlideTopStart,
            fourthSlideTopEnd,
            fifthSlideTopStart,
            fifthSlideTopEnd,
          ],
      ["100vh", "-50vh", "-50vh", "-150vh"]
    ),
    fifth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [fourthSlideTopStart - 0.05, fifthSlideTopEnd - 0.02]
        : [fourthSlideTopStart, fifthSlideTopEnd],
      device === "Mobile" ? ["300vw", "0vw"] : ["100vh", "0vh"]
    ),
    six: useTransform(
      scrollYProgress,
      [
        sixSlideTopStart,
        sixSlideTopEnd,
        formSlideTopStart,
        formSlideTopEnd,
        footerSlideTopStart,
        footerSlideTopEnd,
      ],
      ["100vh", "0vh", "0vh", "-84vh", "-84vh", "-150vh"]
    ),
    backgroundOpacity: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [secondSlideTopStart - 0.1, secondSlideTopStart]
        : [secondSlideTopStart, secondSlideTopStart + 0.02],
      [1, 0]
    ),
    firstWorth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [MobilefirstWorthAppearStart, MobilefirstWorthAppearEnd]
        : [firstWorthAppearStart, firstWorthAppearEnd],
      [0, 1]
    ),
    secondWorth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [MobilesecondWorthAppearStart, MobilesecondWorthAppearEnd]
        : [secondWorthAppearStart, secondWorthAppearEnd],
      [0, 1]
    ),
    thirdWorth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [MobilethirdWorthAppearStart, MobilethirdWorthAppearEnd]
        : [thirdWorthAppearStart, thirdWorthAppearEnd],
      [0, 1]
    ),
    fourthWorth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [MobilefourthWorthAppearStart, MobilefourthWorthAppearEnd]
        : [fourthWorthAppearStart, fourthWorthAppearEnd],
      [0, 1]
    ),
    fifthWorth: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [MobilefifthWorthAppearStart, MobilefifthWorthAppearEnd]
        : [fifthWorthAppearStart, fifthWorthAppearEnd],
      [0, 1]
    ),
    form: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [
            formSlideTopStart - 0.02,
            formSlideTopEnd - 0.02,
            footerSlideTopStart - 0.02,
            footerSlideTopEnd - 0.03,
          ]
        : [
            formSlideTopStart,
            formSlideTopEnd,
            footerSlideTopStart,
            footerSlideTopEnd,
          ],
      device === "Mobile"
        ? ["100vh", "13vh", "13vh", "-35vh"]
        : ["100vh", "13vh", "13vh", "-44vh"]
    ),
    trueFooter: useTransform(
      scrollYProgress,
      device === "Mobile"
        ? [footerSlideTopStart, footerSlideTopEnd - 0.03]
        : [footerSlideTopStart, footerSlideTopEnd],
      device === "Mobile" ? ["100vh", "65vh"] : ["100vh", "46vh"]
    ),
  };

  return (
    <div className={styles.bigContainer} data-scroll-container ref={scrollRef}>
      <div className={styles.stickyBlock} data-scroll-section>
        <motion.div className={styles.mainAbout}>
          <TitleSection top={transforms.first} />
          <ValueSection
            top={transforms.second}
            opacity1={transforms.firstWorth}
            opacity2={transforms.secondWorth}
            opacity3={transforms.thirdWorth}
            opacity4={transforms.fourthWorth}
            opacity5={transforms.fifthWorth}
          />
          <BackgroundAnimation
            backgroundOpacity={transforms.backgroundOpacity}
          />
          <motion.div
            className={styles.writtenAboutUs}
            style={{ top: transforms.third }}
          >
            {device === "Mobile" ? (
              <>
                <div className={styles.blockName}>
                  {t("Customized_solutions")}
                </div>
                <div>
                  <div
                    className={styles.blockTitle}
                    style={{ fontSize: isRtl ? "36px" : "42px" }}
                  >
                    {isRtl ? (
                      <>
                        {t("WE_BELIEVE_THAT_EVERY_ORGANIZATION")}

                        {t("HAS_UNIQUE_TECHNOLOGICAL_NEEDS")}

                        {t("AND_CHALLENGES_THEREFORE")}

                        {t("OUR_APPROACH_IS_FLEXIBLE_AND_ADAPTED")}
                        {t("TO_EACH_CLIENT_PERSONALLY")}
                      </>
                    ) : (
                      <>
                        {t("WE_BELIEVE_THAT_EVERY_ORGANIZATION")}
                        <br />
                        {t("HAS_UNIQUE_TECHNOLOGICAL_NEEDS")}
                        <br />
                        {t("AND_CHALLENGES_THEREFORE")}
                        <br />
                        {t("OUR_APPROACH_IS_FLEXIBLE_AND_ADAPTED")} <br />
                        {t("TO_EACH_CLIENT_PERSONALLY")}
                        <br />
                      </>
                    )}
                  </div>
                  <div className={styles.blockNameMobile}>
                    {t("We_build_the_solutions")} <br />
                    {t("the_existing_infrastructures")}
                    <br />
                    <br />
                    {t("Our_team_of_experts")} <br />
                    {t("understands_the_specific")}
                    <br />
                    {t("adapts_dedicated")} <br />
                    {t("The_result_is_an_innovative")} <br />
                    {t("technology_that_provides")}
                    <br />
                    {t("to_the_customer's_needs")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.blockName}>
                  {t("Customized_solutions")}
                </div>
                <div>
                  <div className={styles.blockTitle}>
                    {t("WE_BELIEVE_THAT_EVERY_ORGANIZATION")}
                    <br />
                    {t("HAS_UNIQUE_TECHNOLOGICAL_NEEDS")}
                    <br />
                    {t("AND_CHALLENGES_THEREFORE")}
                    <br />
                    {t("OUR_APPROACH_IS_FLEXIBLE_AND_ADAPTED")} <br />
                    {t("TO_EACH_CLIENT_PERSONALLY")}
                    <br />
                  </div>
                  <div className={styles.blockName}>
                    {t("We_build_the_solutions")} <br />
                    {t("the_existing_infrastructures")}
                    <br />
                    <br />
                    {t("Our_team_of_experts")} <br />
                    {t("understands_the_specific")}
                    <br />
                    {t("adapts_dedicated")} <br />
                    {t("The_result_is_an_innovative")} <br />
                    {t("technology_that_provides")}
                    <br />
                    {t("to_the_customer's_needs")}
                  </div>
                </div>
              </>
            )}

            <div
              className={styles.bgImage}
              style={{ left: isRtl ? "3vw" : "-3vw" }}
            >
              <img src="/images/aboutWrittenAboutUs.svg" alt="no-image" />
            </div>
          </motion.div>
          <motion.div
            className={styles.ourTeam}
            style={{ top: transforms.fourth }}
          >
            <div
              className={styles.blockName}
              style={{ marginRight: isRtl ? "25px" : "0" }}
            >
              {t("OUR_TEAM")}
            </div>
            <div>
              <div className={styles.blockTitle}>
                {t("WE_BELIEVE_THAT_THE_MOST")}
                <br />
                {t("IMPORTANT_ASSET_IS_HUMAN_CAPITAL")}
                <br />
                {t("THE_PROFESSIONAL_TEAM_THAT_LEADS")}
                <br />
                {t("TECHNOLOGICAL_INNOVATION")}
                <br />
                {t("IN_THE_ORGANIZATION")}
              </div>
              <TeamCarousel />
            </div>
          </motion.div>
          <motion.div
            className={styles.learnMore}
            style={{
              top: transforms.fifth,
              backgroundColor: isRtl ? "#d3dfdb" : "#dbe3e6",
            }}
          >
            <div className={styles.textContainer}>
              <div className={styles.textTitle}>
                <span className={styles.orange}>{t("LEARN_MORE")} </span>
                <br />
                {t("ABOUT_OUR_PRODUCTS")} <br />
                {t("AND_SERVICES")}
              </div>
              <div className={styles.textContent}>
                {t("WeSmart's_advanced")}
                <br />
                {t("successfully_implemented")} <br />
                {t("of_customers_around")} <br />
                {t("real_value_to_businesses")} <br />
                <br />
                {t("If_you_are_interested")} <br />
                {t("the_unique_products")} <br />
                {t("and_how_they_can_help")}
                <br />
              </div>
              <div className={styles.button}>
                <span className={styles.buttonText}>
                  {t("EXPLORE_OUR_PRODUCTS")}
                </span>
              </div>
            </div>
            <div
              className={styles.bgImage}
              style={{ left: device === "Mobile" && isRtl ? "0vw" : "" }}
            >
              <img
                style={{
                  left:
                    device === "Mobile"
                      ? isRtl
                        ? "-188px"
                        : "0" // для мобильных устройств
                      : isRtl
                      ? "0vw"
                      : "50%",
                }}
                src="/images/aboutLearnMoreBG.jpg"
                alt="no-image"
              />
            </div>
          </motion.div>
        </motion.div>
        <motion.div className={styles.weInvite} style={{ top: transforms.six }}>
          <div className={styles.textBlock}>
            <div className={styles.textTitle}>
              {t("WE_INVITE_YOU")}
              <br /> {t("TO_TALK_TO_US")}!
            </div>
            <div className={styles.textContent}>
              {t("At_WeSmart")}
              <br />
              {t("your_questions")}
              <br />
              {t("vision")}
              <br />
              {t("advanced_video_analytics")}
              <br />
              {t("your_organization's_growth")}
              <br />
              <br />
              {t("Our_team_of")}
              <br />
              {t("understand_your_unique")}
              <br />
              {t("customized_technological")}
              <br />
              <br />
              {t("Together_we_will")}
            </div>
          </div>
        </motion.div>
        <Form top={transforms.form} />
        <TrueFooter top={transforms.trueFooter} />
      </div>
      <Footer isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </div>
  );
};

export default About;
