import React, { useEffect, useRef, useState } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import Footer from "../../components/footer/Footer";
import styles from "./Product.module.scss";
import Form from "../../components/Form/Form";
import TrueFooter from "../../components/TrueFooter/TrueFooter";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import Modal from "../../components/homePage/ModalForm/ModalForm";
import useDeviceDetection from "../../hooks/useDeviceDetection";

const startPosition = 0;
const endPosition = 1;

const Product = ({ data }) => {
  const device = useDeviceDetection();
  const { t } = useTranslation();
  const [isRtl, setIsRtl] = useState(false);
  useEffect(() => {
    const language = localStorage.getItem("language"); //
    setIsRtl(language === "heb"); //
  }, []);
  const [scrollInstance, setScrollInstance] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Контроль состояния меню
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleClickAllProducts = () => {
    navigate("/products");
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smoothMobile: true,
      inertia: 0.8,
    });
    setScrollInstance(scroll);
    scrollYProgress.onChange((latest) => {
      const scrollPercentage = latest * 100;
      console.log(`Page scrolled: ${scrollPercentage}%`);
    });

    return () => {
      if (scroll) scroll.destroy();
    };
  }, [scrollYProgress]);

  useEffect(() => {
    if (scrollInstance) {
      if (isMenuOpen) {
        scrollInstance.stop(); // Останавливаем скролл при открытом меню
      } else {
        scrollInstance.start(); // Включаем скролл при закрытом меню
      }
    }
  }, [isMenuOpen, scrollInstance]);

  const anim = useTransform(
    scrollYProgress,
    [startPosition, endPosition],
    ["100vh", "-404vh"]
  );

  return (
    <div className={styles.bigContainer} data-scroll-container ref={scrollRef}>
      <div className={styles.stickyBlock} data-scroll-section>
        <Footer
          transparent={true}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
        <div className={styles.headSection}>
          <div
            className={styles.textTitle}
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontSize: device === "Mobile" ? "6.8vw" : "5.4vw",
              fontWeight: "600",
              textTransform: "uppercase", // Все буквы станут заглавными
            }}
          >
            {t(data.header.title)}
          </div>
          <div className={styles.textContent}>{t("title_description")} </div>
          <div
            className={styles.button}
            onClick={handleModalOpen}
            onClose={handleModalClose}
          >
            <span className={styles.buttonText}>{t("Request_a_demo")}</span>
          </div>
          <img src={data.header.image} alt="no-image" />
          <div className={styles.wrapper}></div>
        </div>
        <motion.div className={styles.pageContainer} style={{ top: anim }}>
          <motion.div className={styles.contentBlock}>
            <div className={styles.ourMission}>
              <div
                className={styles.backButton}
                onClick={handleClickAllProducts}
              >
                {isRtl ? (
                  <GoArrowRight
                    className={styles.arrow}
                    style={{ fontSize: device === "Mobile" ? "22px" : "64px" }}
                  />
                ) : (
                  <GoArrowLeft
                    className={styles.arrow}
                    style={{ fontSize: device === "Mobile" ? "22px" : "64px" }}
                  />
                )}
                <div className={styles.descText} style={{ cursor: "pointer" }}>
                  {t("VIEW_ALL_OTHER_PRODUCTS")}
                </div>
              </div>
              <div>
                <div className={styles.titleText}>
                  {t(data.header.description)}
                </div>
                <div className={styles.textContent}>
                  {t("title_description")}
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div className={styles.safety} style={{ top: "65vh" }}>
            <img
              src={data.sections[0].image}
              alt="no-image"
              className={styles.smallImage}
            />
            <div style={{ order: "2" }}>
              <div className={styles.titleText}>
                {t(data.sections[0].title)}
              </div>
              <div
                className={styles.textContent}
                style={{ color: "rgba(67, 74, 83, 1)" }}
              >
                {t(data.sections[0].description)}
              </div>
            </div>
          </motion.div>
          <motion.div
            className={styles.safety}
            style={{ backgroundColor: "rgba(255, 255, 255, 1)", top: "165vh" }}
          >
            <div style={{ marginLeft: "7vw", marginRight: "7vw", order: "1" }}>
              <div className={styles.titleText}>
                {t(data.sections[1].title)}
              </div>
              <div
                className={styles.textContent}
                style={{ color: "rgba(67, 74, 83, 1)" }}
              >
                {t(data.sections[1].description)}
              </div>
            </div>
            <img
              style={{ order: 2 }}
              src={data.sections[1].image}
              alt="no-image"
              className={styles.smallImage}
            />
          </motion.div>
          <motion.div className={styles.safety} style={{ top: "268vh" }}>
            <img
              src={data.sections[2].image}
              alt="no-image"
              className={styles.smallImage}
            />
            <div style={{ order: "2" }}>
              <div className={styles.titleText}>
                {t(data.sections[2].title)}
              </div>
              <div
                className={styles.textContent}
                style={{ color: "rgba(67, 74, 83, 1)" }}
              >
                {t(data.sections[2].description)}
              </div>
            </div>
          </motion.div>
          <Form top={device === "Mobile" ? "370vh" : "360vh"} />
          <TrueFooter top={device==='Mobile'?"468vh":"450vh"} />
        </motion.div>
      </div>
      <Modal isVisible={isModalVisible} onClose={handleModalClose} />
    </div>
  );
};

export default Product;
