const data = {
    safety: {
      header: {
        title: "safety_header",
        description: "safety_description",
        image: "/safety.jpg",
      },
      sections: [
        {
          title: "safety_section1_title",
          description: "safety_section1_description",
          image: "/images/productImg1.svg",
        },
        {
          title: "safety_section2_title",
          description: "safety_section2_description",
          image: "/images/productImg2.svg",
        },
        {
          title: "safety_section3_title",
          description: "safety_section3_description",
          image: "/images/productImg1.svg",
        },
      ],
    },
  
    publicTransport: {
      header: {
        title: "public_transportation_header",
        description: "public_transportation_descriptio",
        image: "/transport.jpg",
      },
      sections: [
        {
          title: "public_transportation_section1_title",
          description: "public_transportation_section1_description",
          image: "/images/productImg1.svg",
        },
        {
          title: "public_transportation_section2_title",
          description: "public_transportation_section2_description",
          image: "/images/productImg1.svg",
        },
        {
          title: "public_transportation_section3_title",
          description: "public_transportation_section3_description",
          image: "/images/productImg1.svg",
        },
      ],
    },
  
    smartCities: {
      header: {
        title: "smartCities_header",
        description: "smartCities_description",
        image: "/images/smartCitiesSliderBG.svg",
      },
      sections: [
        {
          title: "smartCities_section1_title",
          description: "smartCities_section1_description",
          image: "/images/productImg1.svg",
        },
        {
          title: "smartCities_section2_title",
          description: "smartCities_section2_description",
          image: "/images/productImg1.svg",
        },
        {
          title: "smartCities_section3_title",
          description: "smartCities_section3_description",
          image: "/images/productImg1.svg",
        },
      ],
    },
    logistics: {
        header: {
          title: "logistics_header",
          description: "logistics_description",
          image: "/images/logisticSliderBG.svg",
        },
        sections: [
          {
            title: "logistics_section1_title",
            description: "logistics_section1_description",
            image: "/images/productImg1.svg",
          },
          {
            title: "logistics_section2_title",
            description: "logistics_section2_description",
            image: "/images/productImg1.svg",
          },
          {
            title: "logistics_section3_title",
            description: "logistics_section3_description",
            image: "/images/productImg1.svg",
          },
        ],
      },
      security: {
        header: {
          title: "security_header",
          description: "security_descriptio",
          image: "/images/securitySlidegBG.svg",
        },
        sections: [
          {
            title: "security_section1_title",
            description: "security_section1_description",
            image: "/images/productImg1.svg",
          },
          {
            title: "security_section2_title",
            description: "security_section2_description",
            image: "/images/productImg1.svg",
          },
          {
            title: "security_section3_title",
            description: "security_section3_description",
            image: "/images/productImg1.svg",
          },
        ],
      },
  };
  
  export default data;
  