import { useScroll, motion, useTransform } from "framer-motion";
import styles from "./Careers.module.scss";
import React, { useEffect, useRef, useState } from "react";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../components/footer/Footer";
import TrueFooter from "../../components/TrueFooter/TrueFooter";
import useDeviceDetection from "../../hooks/useDeviceDetection";

const startPosition = 0;
const ourMissionStart = startPosition;
const ourMissionEnd = ourMissionStart + 0.5;
const openPositionsStart = ourMissionEnd;
const openPositionsEnd = openPositionsStart + 0.5;

const Careers = () => {
  const device = useDeviceDetection();
  const [scrollInstance, setScrollInstance] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Контроль состояния меню
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smoothMobile: true,
      inertia: 0.8,
    });
    setScrollInstance(scroll);
    scrollYProgress.onChange((latest) => {
      const scrollPercentage = latest * 100;
      console.log(`Page scrolled: ${scrollPercentage}%`);
    });
    return () => {
      if (scroll) scroll.destroy();
    };
  }, [scrollYProgress]);
  useEffect(() => {
    if (scrollInstance) {
      if (isMenuOpen) {
        scrollInstance.stop(); // Останавливаем скролл при открытом меню
      } else {
        scrollInstance.start(); // Включаем скролл при закрытом меню
      }
    }
  }, [isMenuOpen, scrollInstance]);

  const transforms = {
    ourMission: useTransform(
      scrollYProgress,
      [ourMissionStart, ourMissionEnd],
      device === 'Mobile' ? ['300vw', '-550vw'] : ["110vh", "-185vh"]
    ),
    openPositions: useTransform(
      scrollYProgress,
      device === 'Mobile' ? [openPositionsStart-0.2, openPositionsEnd-0.08] : [openPositionsStart, openPositionsEnd],
      device === 'Mobile' ? ['250vw', '21vw'] : ["120vh", "-6vh"]
    ),
    footer: useTransform(
      scrollYProgress,
      device === 'Mobile' ? [openPositionsStart-0.05, openPositionsEnd-0.08] :[openPositionsStart, openPositionsEnd],
      device === 'Mobile' ? ['250vw', '140vw'] :["157vh", "48.1vh"]
    ),
  };

  return (
    <div className={styles.bigContainer} data-scroll-container ref={scrollRef}>
      <div className={styles.stickyBlock} data-scroll-section>
        <Footer transparent={true} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
        <div className={styles.headSection}>
          <div className={styles.textTitle}>
            Leading the Future with AI-Powered Solutions
          </div>
          <div className={styles.textContent}>
            Let's make the world a better place with cutting-edge solutions
          </div>
          <div className={styles.button}>Explore all jobs</div>
          <img src="/images/careersBG.svg" alt="no-image" />
          <div className={styles.wrapper}></div>
        </div>
        <motion.div
          className={styles.contentBlock}
          style={{ top: transforms.ourMission }}
        >
          <div className={styles.ourMission}>
            <div className={styles.descText}>OUR MISSION</div>
            <div>
              <div className={styles.titleText}>
                WeSmart provides advanced analytics solutions using artificial
                intelligence, allowing organizations to utilize their visual
                data more intelligently and efficiently.
              </div>
              <div className={styles.textContent} style={{marginLeft: '0'}}>
                Through innovative technology, we help improve security, enhance
                operational performance, and empower quick, informed responses
                to critical situations.
              </div>
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.benefitsFirst}>
            <div className={styles.descText}>WHY CHOOSE WESMART?</div>
            <div className={styles.flexRowFirstPage}>
              <div className={styles.flexCol}>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    Simple and User-Friendly Integration
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    Our systems easily integrate with existing infrastructures,
                    delivering accurate, real-time results without the need for
                    replacing current equipment
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    Advanced AI Technology
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    We provide real-time analysis of vast amounts of data,
                    identifying patterns and critical events tailored to the
                    specific needs of each organization
                  </div>
                </div>
              </div>
              <div className={styles.flexCol}>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    Customizable Solutions
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    We adapt our solutions based on the unique requirements of
                    each client, whether it's enhancing operational processes,
                    security, or optimizing business performance
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    Comprehensive Support
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circle}></div>
                  <div className={styles.textContent}>
                    From implementation to ongoing maintenance, we provide full
                    support to ensure the system operates optimally and delivers
                    maximum value to our clients
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.benefits}>
            <div className={styles.title}>Employee Benefits at WeSmart</div>
            <div className={styles.textContent} style={{marginLeft: '0'}}>
              At WeSmart, we foster a culture of innovation and
              forward-thinking, providing our employees with benefits designed
              to support both personal and professional well-being
            </div>
            <div className={styles.flexRow}>
              <div className={styles.flexColSecondSlide}>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Comprehensive Health Coverage
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Full medical, dental, and vision coverage to provide peace
                    of mind for employees and their families
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Flexible Vacation Policies
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Generous vacation days that allow for a healthy work-life
                    balance
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Options to work from the office, remotely, or in a hybrid
                    model, based on personal preferences and needs
                  </div>
                </div>
              </div>
              <div className={styles.flexColSecondSlide}>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>Retirement Savings</div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    401k plans with company matching contributions to help you
                    save for the future
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>Work Flexibility</div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>
                    Opportunity to share in the company’s success through stock
                    options
                  </div>
                </div>
                <div className={styles.textBlock}>
                  <div className={styles.circleSecondPage}></div>
                  <div className={styles.textContent}>Stock Options</div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          className={styles.openPositions}
          style={{ top: transforms.openPositions }}
        >
          <img
            className={styles.bgImg}
            src="/images/careerCube.svg"
            alt="no-image"
          />
          <div className={styles.content}>
            <div className={styles.title}>JOIN US</div>
            <div className={styles.text}>
            Be part of a leading organization that combines innovative technologies with practical solutions. At WeSmart, you can help shape the future, providing analytics solutions that are tailored to the diverse needs of organizations around the world.
            </div>
            <motion.button
              className={styles.animatedButton}
              whileHover="hover"
              initial="initial"
            >
              <motion.div
                className={styles.buttonBackground}
                variants={{
                  initial: { x: "-100%", opacity: 0.2 },
                  hover: { x: 0, opacity: 1 },
                }}
                transition={{ duration: 0.4 }}
              />
              <span className={styles.buttonText}>Follow us on LinkedIn</span>
              <img
                className={styles.icon}
                src="/images/careerIcon.svg"
                alt="no-image"
              />
            </motion.button>
          </div>
        </motion.div>
        <TrueFooter top={transforms.footer} />
      </div>
    </div>
  );
};

export default Careers;
