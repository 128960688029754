import React from "react";
import { motion } from "framer-motion";
import styles from "../../../pages/About/About.module.scss";
import { useNavigate } from "react-router-dom";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const TitleSection = ({ top }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const device = useDeviceDetection();
  const handleClickAllProducts = () => {
    navigate("/products");
  };
  const [isRtl, setIsRtl] = useState(false); // Состояние для проверки языка
  useEffect(() => {
    const language = localStorage.getItem("language"); // или ваш ключ
    setIsRtl(language === "heb"); // true, если иврит
  }, []);
  return (
    <motion.div className={styles.titleBlock} style={{ top: top }}>
      <div
        className={styles.title}
        style={{
          fontFamily: "Open Sans, sans-serif",
          fontSize: device === "Mobile" ? "7vw" : "5.4vw",
          fontWeight: "600",
          textTransform: "uppercase", // Все буквы станут заглавными
          letterSpacing: isRtl ? "-0.05em" : "normal",
          lineHeight: device === "Mobile" ? "48px" : isRtl ? "7.6vw" : "7.3vw",
        }}
      >
        {t("WE_BELIEVE_THAT_TECHNOLOGY")}
        <br />
        {t("IS_MEANT_TO_IMPROVE")}
        <br />
        <span className={styles.orange}>{t("THE_QUALITY_OF_LIFE")}</span>
        <br />
        {t("AND_STREAMLINE_PROCESSES")}
      </div>
      <div className={styles.descriptionBlock}>
        <div className={styles.subtitle}>{t("ABOUT_US")}</div>
        <div className={styles.text}>
          {t("Our_company")}
          <br />
          {t("field_of_analytics")} <br />
          {t("specializes_in_the_development")}
          <br />
          {t("and_innovative_solutions_that _combine")}
          <br />
          {t("different_visual_systems")}
          <br />
          {t("with_challenges")}
          <br />
          <br />
          {t("We_are_proud")}
          <br />
          {t("customers_in_the_economy")}
          <br />
          {t("them_with_advanced")}
          <br />
          {t("to_saving_time")}
        </div>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.imageMobileDiv}>
          <img src="/images/aboutVideo.svg" alt="no-image" />
        </div>
        <div className={styles.wrapper}></div>
        {device === "Mobile" ? (
          <div className={styles.videoTitleContainer}>
            <div className={styles.ourVision}>{t("OUR_VISION")}</div>
            <div className={styles.videoTitle}>
              {isRtl ? (
                <>
                  {t("MAKING_THE_WORLD_A_SAFER")} {t("AND_MORE_EFFICIENT")}{" "}
                  {t("DATA-DRIVEN_VIDEO_TECHNOLOGY")}
                </>
              ) : (
                <>
                  {t("MAKING_THE_WORLD_A_SAFER")}
                  <br />
                  {t("AND_MORE_EFFICIENT")}
                  <br />
                  {t("DATA-DRIVEN_VIDEO_TECHNOLOGY")}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.videoTitle}>
            {t("MAKING_THE_WORLD_A_SAFER")}
            <br />
            {t("AND_MORE_EFFICIENT")}
            <br />
            {t("DATA-DRIVEN_VIDEO_TECHNOLOGY")}
          </div>
        )}

        <div className={styles.videoButton} onClick={handleClickAllProducts}>
          <span className={styles.buttonText}>{t("Explore_our_products")}</span>
        </div>
        <div className={styles.maskedMan}>
          <div
            className={styles.naz}
            style={{ fontSize: device === "Mobile" && isRtl ? "13px" : "24px" }}
          >
            {t("MASKED_MAN")}
          </div>
        </div>
        <div className={styles.gun}>
          <div
            className={styles.naz}
            style={{ fontSize: device === "Mobile" && isRtl ? "13px" : "24px" }}
          >
            {t("GUN")}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TitleSection;
