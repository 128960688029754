import React, { useEffect, useRef, useState } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import styles from "./HomeV2.module.scss";
import Form from "../../components/Form/Form";
import TrueFooter from "../../components/TrueFooter/TrueFooter";
import { GoArrowDown } from "react-icons/go";

import Customers from "../../components/homePage/Customers/Customers";
import Modal from "../../components/homePage/ModalForm/ModalForm";
import Map from "../../components/Map/Map";
import AdvantagesSlider from "../../components/homePage/AdvantagesSlider/AdvantagesSlider";
import AboutUsSlider from "../../components/homePage/AboutUsSlider/AboutUsSlider";
import useDeviceDetection from "../../hooks/useDeviceDetection";

const startPosition = 0;
const slideStart = startPosition + 0.32;
const slideEnd = slideStart + 0.08;
const rotateStart = startPosition;
const rotateEnd = rotateStart + 0.11;
const endPosition = 1;

const HomeV2 = () => {
  const { t } = useTranslation();
  const [isRtl, setIsRtl] = useState(false); // Состояние для проверки языка
  const device = useDeviceDetection();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleClickAllProducts = () => {
    navigate("/products");
  };

  const [scrollInstance, setScrollInstance] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smoothMobile: true,
      inertia: 0.8,
    });
    setScrollInstance(scroll);
    window.scrollTo(0, 0);
    scrollYProgress.onChange((latest) => {
      const scrollPercentage = latest * 100;
      console.log(`Page scrolled: ${scrollPercentage}%`);
    });
    return () => {
      if (scroll) scroll.destroy();
    };
  }, [scrollYProgress, device]);
  useEffect(() => {
    if (scrollInstance) {
      if (isMenuOpen) {
        scrollInstance.stop();
      } else {
        scrollInstance.start();
      }
    }
  }, [isMenuOpen, scrollInstance]);

  useEffect(() => {
    const language = localStorage.getItem("language");
    setIsRtl(language === "heb");
  }, []);

  const anim = useTransform(
    scrollYProgress,
    [rotateEnd + 0.02, slideStart, slideEnd, endPosition],
    device === "Mobile"
      ? ["100vh", "0vh", "0vh", "-350vh"]
      : ["100vh", "-20vh", "-20vh", "-444vh"]
  );

  const rotate = useTransform(
    scrollYProgress,
    [rotateStart, rotateEnd],
    [0, 360]
  );
  const circleOpacity = useTransform(scrollYProgress, [0, 0.11], [1, 0]);

  const clipPath = useTransform(
    scrollYProgress,
    [0, 0.2],
    device === "Mobile"
      ? ["circle(0% at 62.5vw 47vh)", "circle(150% at 62.5vw 47vh)"]
      : ["circle(0% at 54.5vw 67vh)", "circle(150% at 54.5vw 67vh)"]
  );

  const slideLeft = useTransform(
    scrollYProgress,
    [0.32, 0.4],
    isRtl
      ? device === "Mobile"
        ? ["0vw", "370vw"]
        : ["0vw", "80vw"]
      : device === "Mobile"
      ? ["0vw", "-370vw"]
      : ["0vw", "-80vw"]
  );

  return (
    <div className={styles.bigContainer} data-scroll-container ref={scrollRef}>
      {console.log(device)}
      <div className={styles.stickyBlock} data-scroll-section>
        <div className={styles.headSection} style={{ zIndex: 1 }}>
          <div className={styles.bigCircle} />
          <div className={styles.midCircle}>
            <motion.img
              style={{ rotate, opacity: circleOpacity }}
              className={styles.midCircleImage}
              src="/images/loader.svg"
              alt="no-image"
            />
          </div>
          <div className={styles.smallCircle} />
          <div
            className={styles.textTitle}
            style={{
              color: "rgba(54, 59, 97, 1)",
              letterSpacing: isRtl ? "-0.05em" : "normal",
              fontFamily: "Open Sans, sans-serif",
              fontSize: device === "Mobile" ? "7vw" : "5.9vw",
              fontWeight: "600",
              textTransform: "uppercase", // Все буквы станут заглавными
              lineHeight:
                device === "Mobile" ? "48px" : isRtl ? "8.6vw" : "8vw",
            }}
          >
            {t("title_home_1")}
            <br />
            {t("title_home_2")}
            <br />
            {t("title_home_3")}
          </div>
          <div
            className={styles.textContent}
            style={{ color: "rgba(54, 59, 97, 1)" }}
          >
            {t("title_description")}
          </div>
          <div
            className={styles.button}
            onClick={handleModalOpen}
            onClose={handleModalClose}
          >
            <span className={styles.buttonText}> {t("button_home")}</span>
          </div>
          <div className={styles.arrow}>
            <GoArrowDown
              className={styles.arrowDown}
              style={{
                marginTop: device === "Mobile" ? "20vw" : 0,
                marginRight: device === "Mobile" && isRtl ? "75vw" : 0,
              }}
              size={30}
            />
          </div>
        </div>

        <motion.div
          className={styles.headSection}
          style={{ zIndex: 2, clipPath }}
        >
          <div className={styles.whiteCircle}>
            <motion.img
              style={{ rotate, opacity: circleOpacity }}
              className={styles.midCircleImage}
              src="/images/loader.svg"
              alt="no-image"
            />
          </div>
          <div
            className={styles.textTitle}
            style={{
              letterSpacing: isRtl ? "-0.05em" : "normal",

              lineHeight:
                device === "Mobile" ? "48px" : isRtl ? "8.6vw" : "8.8vw",
            }}
          ></div>
          <div className={styles.textContent}> </div>

          <div
            className={` ${styles.button} ${styles.buttonPosition}`}
            style={{
              marginBottom: device === "Mobile" ? "-25vh" : 0,
            }}
            onClick={handleModalOpen}
            onClose={handleModalClose}
          >
            <span className={styles.buttonText}>{t("button_home")}</span>
          </div>

          <video
            className={styles.homeBG}
            src="/wesmart_video_intro.mp4"
            autoPlay
            loop
            muted
            playsInline
          ></video>
          <div className={styles.wrapper}></div>
        </motion.div>

        <motion.div className={styles.pageContainer} style={{ top: anim }}>
          <motion.div className={styles.contentBlock}>
            <div className={styles.ourMission}>
              <div>
                <div className={styles.titleText}>
                  {t("subtitle_home_1")} <br />
                  {t("subtitle_home_2")} <br />
                  {t("subtitle_home_3")} <br />
                  {t("subtitle_home_4")}
                </div>
                <div className={styles.textContent}>
                  {t("subtitle_home_desc_1")} <br />
                  {t("subtitle_home_desc_2")} <br />
                  {t("subtitle_home_desc_3")} <br />
                  {t("subtitle_home_desc_4")}
                </div>
              </div>
            </div>
            <div className={styles.slideArrow}>
              <span>{t("slide")}</span>
              <div className={styles.arrow} style={{ margin: "0" }}></div>
            </div>
          </motion.div>

          <AdvantagesSlider left={slideLeft} />
          <Map />
          <motion.div
            className={styles.safety}
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              top: device === "Mobile" ? "130vh" : "222vh",
            }}
          >
            <Customers />
          </motion.div>
          <AboutUsSlider />

          <Form top={device === "Mobile" ? "318vh" : "400vh"} />
          <TrueFooter top={device === "Mobile" ? "415vh" : "490vh"} />
        </motion.div>
      </div>
      <Modal isVisible={isModalVisible} onClose={handleModalClose} />
      <Footer
        transparent={false}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    </div>
  );
};

export default HomeV2;
