import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import styles from "../../../pages/HomeV2/HomeV2.module.scss";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import useDeviceDetection from "../../../hooks/useDeviceDetection";

const AboutUsSlider = () => {
  const { t } = useTranslation();
  const device = useDeviceDetection();

  // Проверка на язык (если язык иврит - меняем направление)
  const isHebrew = localStorage.getItem('language') === "heb";

  const contentData = [
    {
      text: (
        <>
          {t("working_with")}
          <br />
          {t("by_or_was")}
          <br />
          {t("process")}
          <br />
          {t("experience")} <br />
          {t("improved_the_security")}
          <br />
        </>
      ),
      logoAuthor: (
        <div className={styles.logoAuthor}>
          <img src="/images/homeWrittenAboutUs1.png" alt="no-images" />
          <span>{t("Maariv")}</span>
        </div>
      ),
    },
    {
      text: (
        <>
          {t("the_next_generation")}
        </>
      ),
      logoAuthor: (
        <div className={styles.logoAuthor}>
          <img src="/images/homeWrittenAboutUs2.png" alt="no-images" />
          <span>{t("metzuda")}</span>{" "}
        </div>
      ),
    },
    {
      text: (
        <>
          {t("working")}
        </>
      ),
      logoAuthor: (
        <div className={styles.logoAuthor}>
          <img
            src="/images/homeWrittenAboutUs3.png"
            alt="no-images"
            style={{ width: "5vw" }}
          />
          <span>{t("TechCrunch")}</span>
        </div>
      ),
    },
  ];

  // Состояние для отслеживания текущего индекса
  const [currentIndex, setCurrentIndex] = useState(0);

  // Обработчики нажатий на стрелки
  const handleRightArrowClick = () => {
    if (currentIndex < contentData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleLeftArrowClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
 
  return (
    <motion.div
      className={styles.writtenAboutUs}
      style={{ top: device === "Mobile" ? "227vh" : "314vh" }}
    >
      <div className={styles.blockName}>{t("written_about_us")}</div>
      <div>
        <div className={styles.blockTitle} style={{
              letterSpacing: isHebrew ? "-1px" : "normal",
              lineHeight: device==='Mobile' ? "40px" : isHebrew? '3vw' : '3.2vw',
             }}>
          {contentData[currentIndex].text}
        </div>

        <div className={styles.blockName}>
          {t("the_availability")}
          <br />
          {t("their_system_solved")}
          <br />
          {t("for_us_without")}
          {contentData[currentIndex].logoAuthor}
        </div>
      </div>

      {/* Отображение стрелок в зависимости от языка */}
      {isHebrew ? (
        <>
          {currentIndex > 0 && (
            <div className={styles.rightArrow} onClick={handleLeftArrowClick}>
              <GoArrowRight style={{ color: "#363B61" }} size={35} />
            </div>
          )}
          {currentIndex < contentData.length - 1 && (
            <div className={styles.leftArrow} onClick={handleRightArrowClick}>
              <GoArrowLeft style={{ color: "#363B61" }} size={35} />
            </div>
          )}
        </>
      ) : (
        <>
          {currentIndex < contentData.length - 1 && (
            <div className={styles.rightArrow} onClick={handleRightArrowClick}>
              <GoArrowRight style={{ color: "#363B61" }} size={35} />
            </div>
          )}
          {currentIndex > 0 && (
            <div className={styles.leftArrow} onClick={handleLeftArrowClick}>
              <GoArrowLeft style={{ color: "#363B61" }} size={35} />
            </div>
          )}
        </>
      )}

      <div className={styles.bgImage}>
        <img src="/images/aboutWrittenAboutUs.svg" alt="no-image" />
      </div>

      {/* Навигационные точки */}
      <div className={styles.dotsContainer}>
        {contentData.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              currentIndex === index ? styles.active : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default AboutUsSlider;
